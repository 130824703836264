// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail, GoogleAuthProvider, signInWithPopup, createUserWithEmailAndPassword  } from 'firebase/auth'
import { getFirestore } from "firebase/firestore";

import { getAnalytics } from 'firebase/analytics'

const firebaseConfig = {
    apiKey: "AIzaSyDeA264hjky7oGS4-UIpRLGXvFV34Htkgs",
    authDomain: "democratic-ai-4203d.firebaseapp.com",
    projectId: "democratic-ai-4203d",
    storageBucket: "democratic-ai-4203d.appspot.com",
    messagingSenderId: "137069198241",
    appId: "1:137069198241:web:41d717297d14159116e9e0",
    measurementId: "G-SKEM0BFN7D"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)
const auth = getAuth();
const firestore = getFirestore(firebaseApp);
// const analytics = getAnalytics(app)
const gProvider = new GoogleAuthProvider();

export {
  auth,
  firebaseApp,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  firestore,
  signInWithPopup,
  gProvider,
  createUserWithEmailAndPassword
}