<template>
  <div :class="appTheme">
    <div
      class="flex flex-col h-screen dark:text-white light-text-color bg-white dark:bg-gray-700"
    >
      <header
        x-data="{ mobileMenuOpen : false }"
        class="flex flex-wrap flex-row justify-between md:items-center md:space-x-4 py-2 px-2 top-0 z-50 animated shadow-lg dark:bg-gray-900 bg-white relative h-16"
        ref="nav"
        v-if="!hideTopBar"
      >
        <router-link class="block" :to="{ path: '/' }">
          <span class="text-xl font-bold">民主化AI伦理</span>
        </router-link>
        <span class="flex mx-auto mr-1 mt-1 md:hidden">
          <i class="fa fa-plus mr-4 text-xl" @click="startConversation"></i>
          <ThemeChanger :theme="appTheme"
        /></span>

        <button
          @click="mobileMenuOpen = !mobileMenuOpen"
          class="inline-block md:hidden mt-1 w-8 h-8 p-1"
        >
          <svg
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
        <button
          aria-label="Show sidebar"
          @click.prevent="openSidebar"
          class="md:hidden -mt-2"
        >
          <svg
            stroke="currentColor"
            fill="none"
            stroke-width="2"
            viewBox="0 0 24 24"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="mr-4 text-xl h-6 w-6 text-black dark:text-white"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
            <line x1="9" y1="3" x2="9" y2="21"></line>
          </svg>
        </button>

        <nav
          class="absolute md:relative top-16 left-0 md:top-0 z-20 md:flex flex-col md:flex-row md:space-x-6 font-semibold w-full md:w-auto shadow-md rounded-lg md:rounded-none md:shadow-none p-6 pt-0 md:p-0 dark:bg-gray-900 bg-white"
          :class="{ flex: mobileMenuOpen, hidden: !mobileMenuOpen }"
          click="mobileMenuOpen = false"
        >
          <router-link
            v-if="!user"
            to="/"
            class="block py-1"
            @click="mobileMenuOpen = false"
            ><i class="fa-solid fa-user"></i> Account</router-link
          >

          <router-link
            v-if="user?.role === 'admin'"
            to="/admin/dashboard"
            class="block py-1"
            @click="mobileMenuOpen = false"
          >
            <i class="fa-solid fa-users"></i>
<!--            {{ $t('administrator') }}-->
            管理员
          </router-link>

          <router-link
            v-if="user"
            to="/profile"
            class="block py-1"
            @click="mobileMenuOpen = false"
            ><i class="fa-solid fa-user"></i>
            {{ user.displayName }}
          </router-link>

          <a
            v-if="user"
            class="block py-1 cursor-pointer"
            @click.prevent="logout"
          >
            <i class="fa-solid fa-sign-out"></i>
            登出
          </a>

          <span class="hidden md:flex mt-1">
             <i class="fa fa-plus mr-4 text-xl" @click="startConversation"></i>
            <ThemeChanger :theme="appTheme"
          /></span>

           <button
          aria-label="Show sidebar"
          @click.prevent="openSidebar"
          class="hidden md:flex mt-1"
          v-if="showsidebarBtn"
        >
          <svg
            stroke="currentColor"
            fill="none"
            stroke-width="2"
            viewBox="0 0 24 24"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="mr-4 text-xl h-6 w-6 text-black dark:text-white"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
            <line x1="9" y1="3" x2="9" y2="21"></line>
          </svg>
        </button>
        </nav>
      </header>
      <div class="" ref="main">
        <router-view />
      </div>
    </div>
    <!-- <button
      v-if="top"
      class="backtotop bg-gray-200 dark:bg-gray-900 mb-2"
      v-on:click="scrollTop()"
      title="Go to top"
    >
      <i class="text-black dark:text-white fa fa-arrow-up"></i>
    </button> -->
  </div>
</template>

<script lang="js">
import ThemeChanger from "@/components/ThemeChanger";
import {
    auth
} from '@/services/firebase.ts'

import ButtonComp from "@/components/ButtonComp";
import {
    getDoc,
    doc
} from "firebase/firestore";
import {
    firestore
} from "@/services/firebase.ts";
import { getCurrentInstance, defineComponent, computed } from "vue";
import { useI18n } from 'vue-i18n'

// fancybox.
import {
    Fancybox
} from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
import {
    useThemeStore
} from '@/store/ThemeStore';
import {
    useAuthStore
} from '@/store/auth.ts'


export default {
    name: 'app',
    components: {
        ThemeChanger,
        // ButtonComp,
    },


  watch: {
    async $route(to, from) {
            const base = `民主化AI伦理`
            let title = ""
            if (to.path !== "/") this.path = '/'
            else this.path = ""

            if (typeof to.meta.title === 'string')
                title = `${to.meta.title} - ${base}`
            else if (typeof to.meta.title === 'function')
                title = `${to.meta.title(to)} - ${base}`
            else title = base
            document.title = title

            // if route contain /admin.
            if (to.path.includes('/admin')) {
                this.hideTopBar = true
            } else {
                this.hideTopBar = false
            }

            if (auth.currentUser) {
                // let usr = await this.getUserProfile(auth.currentUser.uid);
                // this.user.role = usr.role
                //  this.authStore.setUser(this.user)
                //           this.user = this.authStore.user.user
                if (to.path.includes('/admin') && this.user?.role !== 'admin') {
                    this.$router.push('/chat')
                }
            }
            // if path include admin.
            if (to.path.includes('/admin')) {
                useThemeStore().changeTheme("light");
            }

            // check if route don't contain /chat then do not show sidebar button.
            if (!to.path.includes('/chat')) {
                this.showsidebarBtn = false
            } else {
                this.showsidebarBtn = true
            }
            // if route contain /admin but user is not admin, redirect to home.
            // if (to.path.includes('/admin') && this.user?.role !== 'admin') {
            //   this.$router.push('/chat')
            // }
            // // if route is / and user is logged in, redirect to chat.
            // if (to.path === '/' && this.authStore.getToken !== null) {
            //   this.$router.push('/chat')
            // }
            // // if path is include word admin, chat, profile and user is not logged in, redirect to login..
            // if (to.path.includes('/admin') || to.path.includes('/chat') || to.path.includes('/profile') && this.authStore.getToken === null) {
            //   this.$router.push('/')
            // }
        },
    },
    data() {
        return {
            hideTopBar: false,
            showModel: false,
            top: false,
            mobileMenuOpen: false,
            hidden: false,
            path: "",
            scroll: 0,
            isLogin: false,
            authStore: useAuthStore(),
            user: null,
            showsidebarBtn: false,
        }
    },
    async created() {
        // back to top button handler.
        //window.addEventListener('scroll', this.handleScroll);
        await this.preload();
    },
    async mounted() {
        await this.preload();
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    computed: {
        appTheme() {
            return useThemeStore().theme;
        },
    },
    methods: {
        startConversation() {
            // check if route is not /chat, redirect to /chat?start=true
            if (this.$route.path !== '/chat') {
                this.$router.push({
                    path: '/chat',
                    query: {
                        start: true
                    }
                })
            } else {
                // if route is /chat, emit startConversation event.
                this.emitter.emit('startConversation')
            }
        },
        openSidebar() {
            this.emitter.emit('openSidebar')
        },
        async preload() {
            this.login = this.authStore.getToken !== null;
            // on change state of login in store.
            if (!this.login) {
                await auth.signOut();
            }
            if (this.login) {
                this.user = auth.currentUser;
                let usr = await this.getUserProfile(auth.currentUser.uid);
                this.user.role = usr?.role
                this.authStore.setUser(this.user)
                this.authStore.setToken(localStorage.getItem('accessToken'));
            }
            auth.onIdTokenChanged(async (user) => {
                if (user) {
                    this.user = user;
                    let usr = await this.getUserProfile(user.uid);
                    let token = await user.getIdToken()
                    this.user.role = usr?.role
                    this.authStore.setUser(this.user)
                    this.authStore.setToken(token);
                } else {
                    this.user = null;
                    this.authStore.setUser(null)
                    this.authStore.setToken(null);
                }
            });
        },
        async getUserProfile(uid) {
            const userRef = doc(firestore, 'users', uid);
            const userDoc = await getDoc(userRef);

            if (userDoc.exists()) {
                return userDoc.data();
            }
            return null;
        },
        async logout() {
            this.mobileMenuOpen = false;
            this.user = null;
            this.login = false;
            await auth.signOut();
            await this.authStore.logout()
            this.$router.push('/')
        },
        doNotShowModel() {
            this.showModel = false
            localStorage.setItem('showModel', false)
        },
        scrollTop() {
            window.scrollTo(0, 0);
        },
        manageNavScroll(t) {
            let nav = this.$refs.nav
            let main = this.$refs.main
            if (t === 'add') {
                nav.classList.add('fixed')
                nav.classList.add('w-full')
                nav.classList.add('top-0')
                nav.classList.remove('relative')
                main.classList.add('mt-20')
                return;
            }
            nav.classList.remove('fixed')
            nav.classList.remove('w-full')
            nav.classList.remove('top-0')
            nav.classList.add('relative')
            main.classList.remove('mt-20')
        },
        handleScroll(event) {
            let scroll = document.body.scrollTop || document.documentElement.scrollTop;
            if (scroll > 150) {
                if (scroll > 300) {
                    if (this.scroll < scroll) {
                        this.manageNavScroll('remove')
                    } else {
                        this.manageNavScroll('add')
                    }
                }

                this.top = true
            } else {
                this.top = false
                this.manageNavScroll('remove')
            }
            this.scroll = scroll;
        },
    },
}
</script>
